<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>报废办理提醒</span>
    </div>

    <div class="enterprise">
      <div class="body-center">
        <div style="margin-right: 29px;">
          <p class="model-top">城西业务部</p>
          <div class="model-body">
            <img class="model-icon" src="/img/dizhi.png" alt="" />
            <p class="model-font">
              <span>地址：陕西省西安市未央区三桥国际·汽车欢乐港内45排61号</span>
            </p>
          </div>
          <p class="model-font">
            <img class="model-icon" src="/img/dianhua.png" alt="" />
            <span>电话：029-89108049</span>
          </p>
          <img class="model-img" src="/img/chengxi.jpg" alt="" />
        </div>
        <div>
          <p class="model-top">城南业务部</p>
          <div class="model-body">
            <img class="model-icon" src="/img/dizhi.png" alt="" />
            <p class="model-font">
              <span>地址：陕西省西安市长安区雁翔路903号西安再生资源市场大门内</span>
            </p>
          </div>
          <p class="model-font">
            <img class="model-icon" src="/img/dianhua.png" alt="" /><span>电话：029-89683700</span>
          </p>
          <img class="model-img" src="/img/chengnan.jpg" alt="" />
        </div>
      </div>
      <div class="body-center">
        <div style="margin-right: 29px;">
          <p class="model-top">城东业务部</p>
          <div class="model-body">
            <img class="model-icon" src="/img/dizhi.png" alt="" />
            <p class="model-top">
              <span>地址：陕西省西安市新城区石家街中企钢贸城内东排109号</span>
            </p>
          </div>
          <p class="model-top">
            <img class="model-icon" src="/img/dianhua.png" alt="" /><span>电话：029-84245233</span>
          </p>
          <img class="model-img" src="/img/chengdong.jpg" alt="" />
        </div>
        <div>
          <p class="model-top">城北业务部</p>
          <div class="model-body">
            <img class="model-icon" src="/img/dizhi.png" alt="" />
            <p class="model-top">
              <span>地址 ： 陕西省西安市未央区名京九合院 8 号楼 1 层东 1 号</span>
            </p>
          </div>
          <p class="model-top">
            <img class="model-icon" src="/img/dianhua.png" alt="" /><span>电话：029-86452034</span>
          </p>
          <img class="model-img" src="/img/chengbei.jpg" alt="" />
        </div>
      </div>
    </div>
    <el-button class="bottom-btn" size="small" @click="handleCancel">返回填写信息</el-button>
  </el-card>
</template>

<script>
export default {
  name: "fillInTheOrder",
  data() {
    return {};
  },
  methods: {
    handleCancel() {
      this.$router.push({
        path: "/scrap/firstScrap",
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 1200px; /* no */
  margin: 20px auto; /* no */
}
.clearfix {
  font-size: 18px; /* no */
}
.enterprise {
  margin: 0 auto;
  width: 80%;
  padding: 29px 0 50px 0; /* no */
}
.body-center {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* no */
}
.model-top {
  font-size: 16px; /* no */
  margin-bottom: 20px; /* no */
}
.model-body {
  display: flex;
}
.model-icon {
  width: 32px; /* no */
  height: 32px; /* no */
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; /* no */
}
.model-font {
  font-size: 16px; /* no */
  margin-bottom: 20px; /* no */
}
.model-img {
  width: 100%;
  height: 360px; /* no */
}
.bottom-btn {
  width: 120px; /* no */
  height: 40px; /* no */
  background: #24a2aa;
  color: white;
  font-size: 16px; /* no */
  border: solid 1px #24a2aa; /* no */
  margin-left: 30px; /* no */
}
</style>
